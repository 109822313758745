export function reduceSituations(equipments) {
  const initial = {
    SUCCESS: 0,
    WARNING: 0,
    DANGER: 0,
    DARK: 0,
  };
  return equipments.reduce((acc, equipment) => {
    acc[equipment.situation] += 1;
    return acc;
  }, initial);
}
