import { useState, useEffect } from "react";

import api from "../services/api";
import history from "./history";
import Swal from "sweetalert2";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [prefix, setPrefix] = useState("");
  const [itemContractId, setItemContractId] = useState();
  const [attachments, setAttachments] = useState(false);
  const [editEquipament, setEditEquipament] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [editAttributes, setEditAttributes] = useState();
  const [rememberPassword, setRememberPassword] = useState();
  const [expires, setExpires] = useState("");
  const [addIdContract, setAddIdContract] = useState();
  const [isActiveToggle, setIsActiveToggle] = useState(false);
  const [redirectPage, setRedirectPage] = useState("");
  const [alertNotification, setAlertNotification] = useState();
  const [alertClient, setAlertClient] = useState();
  const [os, setOs] = useState(); //número da OS
  const [showOs, setShowOs] = useState(false); //exibe ou não OS
  const [contractOs, setContractOs] = useState();
  const [editContractOs, setEditContractOs] = useState(null);
  const [dashboard, setDashboard] = useState(1);
  const [attachmentOs, setAttachmentOs] = useState(null);
  const [idContractLicenses, setIdContractLicenses] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [listUnitsWz, setListUnitsWz] = useState([]);
  const [listSectorsWz, setListSectorsWz] = useState([]);
  const [showUnitsWz, setShowUnitsWz] = useState(false);
  const [showRoomsWz, setShowRoomsWz] = useState(false);
  const [showSectorsWz, setShowSectorsWz] = useState(false);
  const [selectclient, setSelectClient] = useState("");
  const [selectuf, setSelectUf] = useState("");
  const [selectcity, setSelectCity] = useState("");
  const [selectunit, setSelectUnit] = useState("");
  const [selectsector, setSelectSector] = useState("");
  const [osItemServices, setOsItemServices] = useState({
    service: null,
    attach: null,
    comment: null,
  });
  const [showEquipmentView, setShowEquipmentView] = useState("");
  const [showNotificationView, setShowNotificationView] = useState([]);
  const [viewSismogran, setViewSismogran] = useState(null);
  const [monitoringNotifications, setMonitoringNotifications] = useState(null);
  const [idProposal, setIdProposal] = useState(null);
  const [viewEquipments, setViewEquipments] = useState({
    idEquipment: null,
    idCustomer: null,
    idEquipmentModel: null,
    equipments: true,
    addEquipments: false,
    editEquipments: false,
    attachments: false,
    notifications: false,
    tracks: false,
    page: 1,
    perPage: 15,
    serach: "",
    orderBy: "",
    order: "",
  }); //Exibições de Itens de Contratos no novo modal
  const [employeeView, setEmployeeView] = useState({
    employees: true,
    equipmentView: false,
    telegramView: false,
    addEmployees: false,
    linkTelegram: false,
    idUser: null,
    idCustomer: null,
    chatId: null,
  });
  const [customerActionPlan, setCustomerActionPlan] = useState(false);
  const [showNotificationTimer, setShowNotificationTimer] = useState(false);

  function updateEditEquipament() {
    setDataEdit();
  }

  function showAccess(e) {
    return { email, password, rememberPassword };
  }

  function handleEmail(e) {
    setEmail(e);
  }

  function handlePassword(e) {
    setPassword(e);
  }

  function refreshPage() {
    window.location.reload();
  }

  function redirectTo() {
    history.push("/clientes");
  }

  function redirect(path) {
    history.push(path);
    refreshPage();
  }

  useEffect(() => {
    const token = localStorage.getItem("@token_mtechsp");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      setAuthenticated(true);
    } else {
      localStorage.clear();
    }
    setLoading(false);
  }, []);

  /*Preenche os estados assim que a página carrega, pegando do localStorage */
  useEffect(() => {
    const rememberLocalStorage = JSON.parse(
      localStorage.getItem("@remember_password")
    );

    if (rememberLocalStorage) {
      // Verifica se o token expirou
      if (new Date().getTime() > rememberLocalStorage.expires) {
        localStorage.removeItem("@remember_password");
      } else {
        setEmail(rememberLocalStorage.login);
        setPassword(rememberLocalStorage.password);
        setRememberPassword(rememberLocalStorage.remember);
        setExpires(rememberLocalStorage.expires);
      }
    }
  }, []);

  useEffect(() => {
    /* Atualiza o estado sempre que Lembrar acesso esteja marcado */
    if (rememberPassword) {
      /* Define a data em que o token ira expirar */
      if (expires == "") {
        let data = new Date();
        let deadline = 5;
        data.setDate(data.getDate() + deadline);
        setExpires(data.getTime());
      }

      localStorage.setItem(
        "@remember_password",
        JSON.stringify({
          login: email,
          password: password,
          remember: rememberPassword,
          expires: expires,
        })
      );
    } else {
      localStorage.removeItem("@remember_password");
    }
  }, [email, expires, password, rememberPassword]);

  async function handleLogin(event) {
    event.preventDefault();
    try {
      const response = await api.post("entrar", {
        email: email,
        password: password,
      });
      localStorage.setItem("@fn_mtechsp", JSON.stringify(response.data.id));
      localStorage.setItem(
        "@token_mtechsp",
        JSON.stringify(response.data.token)
      );
      localStorage.setItem("@user_mtechsp", JSON.stringify(response.data.name));
      localStorage.setItem("@user_group", JSON.stringify(response.data.group));

      setAuthenticated(true);

      if (
        response.data.group == "customer" &&
        response.data.customer.licenses.filter(
          (fill) => fill.label == "DTLAB"
        )[0].label == "DTLAB" &&
        response.data.customer.licenses.filter(
          (fill) => fill.label == "DTLAB"
        )[0].active == 1
      ) {
        history.push("/gestao/cliente/inicio");
      } else if (
        response.data.group == "customer" &&
        response.data.customer.licenses.filter(
          (fill) => fill.label == "MONITORAMENTO"
        )[0].label == "MONITORAMENTO" &&
        response.data.customer.licenses.filter(
          (fill) => fill.label == "MONITORAMENTO"
        )[0].active == 1
      ) {
        history.push("/monitoramento/inicio");
      } else if (response.data.group == "tech") {
        history.push("/planejamento/inicio");
      } else if (response.data.group == "commercial") {
        history.push("/comercial/propostas");
      } else {
        history.push("/gestao/inicio");
      }

      refreshPage();
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            err.response.status == 500
              ? "Houve um problema com o login, verifique suas credenciais ou tente novamente."
              : err.response.data.message,
          confirmButtonText: "Fechar",
        }).then((result) => {
          if (result.isConfirmed) {
            handleLogout();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Existe um problema de acesso em sua conta. Por favor entre em contato conosco!",
          confirmButtonText: "Fechar",
        }).then((result) => {
          if (result.isConfirmed) {
            handleLogout();
          }
        });
      }
    }
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.clear();
    api.defaults.headers.Authorization = undefined;
    history.push("/");
  }

  return {
    employeeView,
    setEmployeeView,
    viewEquipments,
    setViewEquipments,
    viewSismogran,
    setViewSismogran,
    osItemServices,
    setOsItemServices,
    showUnitsWz,
    setShowUnitsWz,
    showRoomsWz,
    setShowRoomsWz,
    showSectorsWz,
    setShowSectorsWz,
    listSectorsWz,
    setListSectorsWz,
    listUnitsWz,
    setListUnitsWz,
    loadingPage,
    setLoadingPage,
    idContractLicenses,
    setIdContractLicenses,
    showOs,
    setShowOs,
    attachmentOs,
    setAttachmentOs,
    dashboard,
    setDashboard,
    editContractOs,
    setEditContractOs,
    contractOs,
    setContractOs,
    os,
    setOs,
    alertClient,
    setAlertClient,
    alertNotification,
    setAlertNotification,
    updateEditEquipament,
    redirectPage,
    setRedirectPage,
    isActiveToggle,
    setIsActiveToggle,
    addIdContract,
    setAddIdContract,
    editAttributes,
    setEditAttributes,
    dataEdit,
    setDataEdit,
    editEquipament,
    setEditEquipament,
    redirectTo,
    itemContractId,
    setItemContractId,
    refreshPage,
    attachments,
    setAttachments,
    authenticated,
    setAuthenticated,
    loading,
    handleLogin,
    handleLogout,
    handleEmail,
    handlePassword,
    setRememberPassword,
    showAccess,
    redirect,
    selectclient,
    setSelectClient,
    selectuf,
    setSelectUf,
    selectcity,
    setSelectCity,
    selectunit,
    setSelectUnit,
    selectsector,
    setSelectSector,
    showEquipmentView,
    setShowEquipmentView,
    showNotificationView,
    setShowNotificationView,
    monitoringNotifications,
    setMonitoringNotifications,
    idProposal, // Id de proposta indo para Componentes de proposta.
    setIdProposal,
    setCustomerActionPlan,
    customerActionPlan,
    showNotificationTimer,
    setShowNotificationTimer,
  };
}
