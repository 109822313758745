import styled from "styled-components";

export const Notification = styled.img`
  cursor: pointer;
  margin-left: 15px;

  &:hover {
    opacity: 0.5;
  }
`;

export const Content = styled.div`
  max-width: 100%;
  background: #ebebeb;
  border-radius: 15px;
  padding: 15px 0;
  margin: 5px;

  display: flex;
  flex-direction: column;
  gap: 18px;

  align-items: stretch;
`;
