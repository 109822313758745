import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Battery = ({ level, signal }) => {
  return (
    <>
      {
        <span style={{ marginRight: '5px', marginTop: '1px' }}>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {signal != 4 && level == 0
                  ? 'Este equipamento não tem bateria!'
                  : 'Bateria do equipamento'}
              </Tooltip>
            }
          >
            <img
              src={`/media/battery/${
                signal != 4 && level == 0
                  ? 'no-battery.svg'
                  : level == 0
                  ? 'battery0.svg'
                  : level <= 16.66
                  ? 'battery1.svg'
                  : level <= 33.32
                  ? 'battery2.svg'
                  : level <= 49.98
                  ? 'battery3.svg'
                  : level <= 66.64
                  ? 'battery4.svg'
                  : level <= 83.3
                  ? 'battery5.svg'
                  : 'battery6.svg'
              }`}
              width="25"
            />
          </OverlayTrigger>
        </span>
      }
    </>
  );
};
export default Battery;
