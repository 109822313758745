import axios from 'axios';

const token = localStorage.getItem('@token_mtechsp');

const api = axios.create({
  baseURL: 'https://api.dtlab4.dtlab.com.br/api/',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
export default api;