import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fontawesome from "../../../../components/fontawesome/fontawesome";
import {
  LocationMonitoring,
  IconLocationMonitoring,
  IconArrowLocation,
  Location,
  TotalItems,
  Button,
  Situations,
  Status,
  Chip,
  StatusText,
  ButtonIcon,
  Rotate,
} from "./styles";
import { useFetch } from "../../../../../components/hooks/useFetch";
import { Prefix } from "../../../../../services/prefix";

import Loading from "../../../../../components/loader/loading";
import { MonitoringCard } from "../MonitoringCard";

const RoomMonitoring = ({
  idUnit,
  nameUnit,
  idRoom,
  idSector,
  customerName,
  setAllRooms,
}) => {
  const [opens, setOpens] = useState([]);

  function handleOpen(id) {
    setOpens((state) =>
      state.includes(id) ? state.filter((it) => it !== id) : [...state, id]
    );
  }

  function isOpen(id) {
    return opens.includes(id);
  }

  const params = Object.entries({
    sector: idSector,
    room: idRoom,
  })
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  const path = `${Prefix.replace(
    /[\\"]/g,
    ""
  )}/sismogran/map/units/${idUnit}/rooms?${params}`;

  const listRooms = useFetch(path);

  const invertArray = listRooms.data?.models.sort(
    (a, b) => b.contractItems.length - a.contractItems.length
  );

  function reduceSituations(equipments) {
    const initial = {
      SUCCESS: 0,
      WARNING: 0,
      DANGER: 0,
      DARK: 0,
    };
    return equipments.reduce((acc, equipment) => {
      acc[equipment.situation] += 1;
      return acc;
    }, initial);
  }

  useEffect(() => {
    if (!listRooms.data?.models) return;

    setAllRooms((state) => ({ ...state, [idUnit]: listRooms.data?.models }));

    return () => setAllRooms((state) => ({ ...state, [idUnit]: [] }));
  }, [idUnit, listRooms.data, setAllRooms]);

  if (!listRooms.data) return <Loading />;

  return (
    <>
      {invertArray?.map((room, index) => {
        const situations = reduceSituations(room.contractItems);

        const open = isOpen(room.id);

        return (
          <Col sm={12} key={index}>
            <LocationMonitoring>
              <Location>
                <IconLocationMonitoring src="../../../media/icons/Location.svg" />{" "}
                {["admin", "master"].includes(Prefix.replace(/[\\"]/g, "")) && (
                  <>
                    {customerName}
                    <IconArrowLocation src="../../../media/icons/arrow-location.svg" />
                  </>
                )}
                {nameUnit}
                <IconArrowLocation src="../../../media/icons/arrow-location.svg" />
                {room.sector}
                <IconArrowLocation src="../../../media/icons/arrow-location.svg" />
                {room.name}
              </Location>
              <Situations>
                <Chip situation={"SUCCESS"}>
                  <Status src="/media/icons/green-light.svg" />
                  <StatusText>{situations?.SUCCESS || "--"}</StatusText>
                </Chip>
                <Chip situation="WARNING">
                  <Status src="/media/icons/yellow-light.svg" />
                  <StatusText>{situations?.WARNING || "--"}</StatusText>
                </Chip>
                <Chip situation="DANGER">
                  <Status src="/media/icons/red-light.svg" />
                  <StatusText>{situations?.DANGER || "--"}</StatusText>
                </Chip>
                <Chip situation="DARK">
                  <Status src="/media/icons/black-light.svg" />
                  <StatusText>{situations?.DARK || "--"}</StatusText>
                </Chip>
              </Situations>
              <TotalItems>
                <Button onClick={() => handleOpen(room.id)}>
                  <strong>{room.contractItems.length}</strong>
                  <div style={{ marginLeft: 4 }}>Equipamento(s)</div>
                  <ButtonIcon>
                    <Rotate open={open}>
                      <Fontawesome classe="fas fa-chevron-down mr-2 ml-2" />
                    </Rotate>
                  </ButtonIcon>
                </Button>
              </TotalItems>
            </LocationMonitoring>

            {open && (
              <Row className="mt-4">
                {room?.contractItems?.map((item, index) => (
                  <MonitoringCard item={item} key={index} />
                ))}
              </Row>
            )}
          </Col>
        );
      })}
    </>
  );
};
export { RoomMonitoring };
