import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Loading from "../../../components/loader/loading";

import Sidebar from "../../../components/sidebar/sidebar";
import Topbar from "../../../components/topbar/topbar";

import "./sensorsDashboard.css";
import { RoomMonitoring } from "./components/RoomMonitoring";
import { Prefix } from "../../../services/prefix";
import { useFetch } from "../../../components/hooks/useFetch";
import { Context } from "../../../services/context";
import DetailsMonitoring from "../../components/detailsMonitoring/detailsMonitoring";
import { AsyncPaginate } from "react-select-async-paginate";
import { NoOptionsMessage } from "../../../components/selectAsync/NoOptionsMessage";
import { loadOptions } from "../../../components/selectAsync/loadOptions";
import { Content } from "./mapStyle";
import {
  Chip,
  LocationMonitoring,
  Status,
  StatusText,
} from "./components/RoomMonitoring/styles";
import { reduceSituations } from "./utils";

const SensorsDashboard = () => {
  const [full, setFull] = useState(true);
  const { viewSismogran, setViewSismogran } = useContext(Context);
  const [allRooms, setAllRooms] = useState({});

  const [valueSelect, setValueSelect] = useState([]);

  const HandleSelect = (event, name) => {
    const updatedValueSelect = [...valueSelect].filter(
      (it) => it.nameField !== name
    );
    if (event) {
      updatedValueSelect.push({
        value: event.value,
        label: event.label,
        nameField: name,
      });
    }
    setValueSelect(updatedValueSelect);
  };

  const getValue = (nameField) =>
    valueSelect.find((it) => it.nameField === nameField)?.value || "";

  const idCustomer = getValue("id_customer");

  const idUnit = getValue("id_unit");

  const idSector = getValue("id_sector");

  const idRoom = getValue("id_room");

  const units = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/sismogran/map/units" +
      (idCustomer ? `?id_customer=${idCustomer}` : "")
  );

  const overallSituations = useMemo(() => {
    if (Object.keys(allRooms).length === 0) return null;
    if (!units.data) return null;

    const unitsIds = units.data.models?.map((it) => it.id);

    const equipments = unitsIds
      .map((id) => allRooms[id])
      .filter((it) => !!it)
      .flat()
      .flatMap((it) => it.contractItems);

    return reduceSituations(equipments);
  }, [allRooms, units.data]);

  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            {!units.data ? (
              <Loading />
            ) : (
              <Col className="card pb-3" style={{ borderRadius: 20 }}>
                <Row className="mt-4">
                  <Col
                    md={10}
                    className="d-flex justify-content-between filterMonit"
                  >
                    {["admin", "master"].includes(
                      Prefix.replace(/[\\"]/g, "")
                    ) && (
                      <AsyncPaginate
                        isClearable
                        value={valueSelect
                          .filter((fill) => fill.nameField === "id_customer")
                          .map((map) => {
                            return {
                              value: map.value,
                              label: map.label,
                            };
                          })}
                        loadOptions={loadOptions}
                        components={{ NoOptionsMessage }}
                        onChange={(e) => HandleSelect(e, "id_customer")} //values and name of select paginate
                        placeholder="Filtrar por cliente..."
                        cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                        classNamePrefix="filterMonitoring"
                        additional={{
                          page: 1,
                          endpoint: "/sismogran/map/customers",
                        }}
                      />
                    )}
                    <AsyncPaginate
                      isClearable
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_unit")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      components={{ NoOptionsMessage }}
                      onChange={(e) => HandleSelect(e, "id_unit")} //values and name of select paginate
                      placeholder="Filtrar por unidade..."
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      classNamePrefix="filterMonitoring"
                      additional={{
                        page: 1,
                        endpoint: "/units",
                        params: idCustomer ? `id_customer=${idCustomer}` : "", //Separete with & (and commercial)
                      }}
                    />
                    <AsyncPaginate
                      isClearable
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_sector")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      components={{ NoOptionsMessage }}
                      onChange={(e) => HandleSelect(e, "id_sector")} //values and name of select paginate
                      placeholder="Filtrar por setor..."
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      classNamePrefix="filterMonitoring"
                      additional={{
                        page: 1,
                        endpoint: "/sectors",
                        params: idUnit ? `id_unit=${idUnit}` : "", //Separete with & (and commercial)
                      }}
                    />

                    <AsyncPaginate
                      value={valueSelect
                        .filter((fill) => fill.nameField == "id_room")
                        .map((map) => {
                          return {
                            value: map.value,
                            label: map.label,
                          };
                        })}
                      loadOptions={loadOptions}
                      isClearable
                      components={{ NoOptionsMessage }}
                      onChange={(e) => HandleSelect(e, "id_room")} //values and name of select paginate
                      placeholder="Filtrar por sala"
                      cacheUniqs={[valueSelect]} //Set your param that is going to change when you select another field. Just use this option when you are setting params and need to refresh cache.
                      classNamePrefix="filterMonitoring"
                      additional={{
                        page: 1,
                        endpoint: "/rooms",
                        params: `id_unit=${idUnit}&id_sector=${idSector}`, //Separete with & (and commercial)
                      }}
                    />
                  </Col>
                </Row>
                <Content>
                  <Col sm={12}>
                    <LocationMonitoring
                      style={{ justifyContent: "center", paddingLeft: 0 }}
                    >
                      <Chip situation={"SUCCESS"}>
                        <Status src="/media/icons/green-light.svg" />
                        <StatusText>
                          {overallSituations?.SUCCESS || "--"}
                        </StatusText>
                      </Chip>
                      <Chip situation="WARNING">
                        <Status src="/media/icons/yellow-light.svg" />
                        <StatusText>
                          {overallSituations?.WARNING || "--"}
                        </StatusText>
                      </Chip>
                      <Chip situation="DANGER">
                        <Status src="/media/icons/red-light.svg" />
                        <StatusText>
                          {overallSituations?.DANGER || "--"}
                        </StatusText>
                      </Chip>
                      <Chip situation="DARK">
                        <Status src="/media/icons/black-light.svg" />
                        <StatusText>
                          {overallSituations?.DARK || "--"}
                        </StatusText>
                      </Chip>
                    </LocationMonitoring>
                  </Col>
                  {units.data?.models
                    .filter((unit) => (idUnit ? idUnit === unit.id : true))
                    ?.map((it, index) => (
                      <RoomMonitoring
                        idUnit={it.id}
                        nameUnit={it.name}
                        key={index}
                        idCustomer={idCustomer}
                        idSector={idSector}
                        idRoom={idRoom}
                        customerName={it.customer}
                        setAllRooms={setAllRooms}
                      />
                    ))}
                </Content>
              </Col>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={!!viewSismogran}
        onHide={() => setViewSismogran()}
        size={"xl"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex justify-content-between">
              <div>
                {viewSismogran && (
                  <img
                    src="../../../media/icons/left-arrow.svg"
                    className="mr-4"
                    onClick={() => setViewSismogran()}
                    alt="Voltar"
                  />
                )}{" "}
                <>
                  Voltar
                  {viewSismogran && (
                    <Button
                      variant="success"
                      className="ml-4"
                      onClick={() => setFull(!full ? true : false)}
                    >
                      {full ? "Aumentar Visualização" : "Reduzir Visualização"}
                    </Button>
                  )}
                </>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailsMonitoring sensor={viewSismogran} full={full} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export { SensorsDashboard };
