import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./lights.css";
import { useLocation } from "react-router-dom";

const Lights = ({ signal, message, classe }) => {
  let location = useLocation();
  let pathname = location.pathname.split("/")[2];
  let animation = pathname == "alertas" ? "" : "animation";

  return (
    <>
      {signal == 1 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
        >
          <span
            className={
              !classe
                ? animation + " d-inline-block"
                : animation + " d-inline-block " + classe
            }
          >
            <img src="/media/icons/green-light.svg" width="20" />
          </span>
        </OverlayTrigger>
      ) : signal == 2 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
        >
          <span
            className={
              !classe
                ? animation + " d-inline-block"
                : animation + " d-inline-block " + classe
            }
          >
            <img src="/media/icons/yellow-light.svg" width="20" />
          </span>
        </OverlayTrigger>
      ) : signal == 3 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
        >
          <span
            className={
              !classe
                ? animation + " d-inline-block"
                : animation + " d-inline-block " + classe
            }
          >
            <img src="/media/icons/red-light.svg" width="20" />
          </span>
        </OverlayTrigger>
      ) : signal == 4 ? (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
        >
          <span
            className={!classe ? "d-inline-block" : "d-inline-block " + classe}
          >
            <img src="/media/icons/black-light.svg" width="20" />
          </span>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{message}</Tooltip>}
        >
          <span
            className={!classe ? "d-inline-block" : "d-inline-block " + classe}
          >
            <img src="/media/icons/purple-light.svg" width="20" />
          </span>
        </OverlayTrigger>
      )}
    </>
  );
};
export default Lights;
