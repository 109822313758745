import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  CardMonitoring,
  TitleCardMonitoring,
  TabletIcon,
  TitleBoxOne,
  TitleBoxTwo,
  TagMonitoring,
  Measure,
  IconMeasure,
  LimitMeasure,
  RealMeasure,
  InsideLimitMeasure,
  TagInfo,
  LastReading,
  Ellipse,
  NoRead,
} from "./styles";
import Signal from "../../../../components/signal/signal";
import Lights from "../../../../components/lights/lights";
import Battery from "../../../../components/battery/battery";
import moment from "moment";
import { versionCollector } from "../../../../../components/data/versionCollector";
import { Context } from "../../../../../services/context";

export function MonitoringCard({ item }) {
  const { setViewSismogran } = useContext(Context);
  return (
    <Col md={3}>
      <Link onClick={() => setViewSismogran(item.id)}>
        <CardMonitoring type={item.monitor_environment} className="mb-3">
          <TitleCardMonitoring>
            <TitleBoxOne title={item.nickname}>
              <TabletIcon src="../../../media/icons/tablet.svg" />
              {item.nickname}
            </TitleBoxOne>
            <TitleBoxTwo>
              <Signal
                signal={item.signal_quality}
                network={item.network}
                situation={item.situation}
              />
              <Battery
                level={item.batterylevel}
                signal={
                  item.situation == "SUCCESS"
                    ? 1
                    : item.situation == "WARNING"
                    ? 2
                    : item.situation == "DANGER"
                    ? 3
                    : item.situation == "DARK"
                    ? 4
                    : 5
                }
              />
              <Lights
                message={item.message}
                signal={
                  item.situation == "SUCCESS"
                    ? 1
                    : item.situation == "WARNING"
                    ? 2
                    : item.situation == "DANGER"
                    ? 3
                    : item.situation == "DARK"
                    ? 4
                    : 5
                }
              />
            </TitleBoxTwo>
          </TitleCardMonitoring>
          <TagMonitoring type={item.monitor_environment}>
            {!item.monitor_environment
              ? "Coletor de equipamento"
              : "Coletor de ambiente"}
          </TagMonitoring>
          <Row className="m-2">
            {item.sensors?.length == 0 ? (
              <Col md={12}>
                <NoRead>Sem leituras</NoRead>
              </Col>
            ) : (
              item.sensors?.map((sens) => (
                <Col>
                  <Measure>
                    <RealMeasure>
                      <IconMeasure
                        src={
                          sens.name_measure == "Temperatura"
                            ? "../../../media/icons/temperature.svg"
                            : "../../../media/icons/humidity.svg"
                        }
                      />
                      {!sens.value ? 0.0 : sens.value}
                      {!sens.name_unitOfMeasure
                        ? ""
                        : sens.name_measure == "Umidade"
                        ? " %" + sens.name_unitOfMeasure
                        : sens.name_unitOfMeasure}
                    </RealMeasure>

                    <LimitMeasure>
                      <InsideLimitMeasure>
                        <strong>Min</strong>
                        <span>{sens.min}</span>
                      </InsideLimitMeasure>
                      <InsideLimitMeasure>
                        <strong>Máx</strong>
                        <span>{sens.max}</span>
                      </InsideLimitMeasure>
                    </LimitMeasure>
                  </Measure>
                </Col>
              ))
            )}
          </Row>
          <TagInfo>
            <strong>Coletor: </strong> {item.serial}{" "}
            <Ellipse src="../../../media/icons/ellipse.svg" />
            <strong>Tag: </strong>{" "}
            {versionCollector.filter((fill) => fill.value == item.version)
              .length == 0
              ? "Nenhuma"
              : versionCollector
                  .filter((fill) => fill.value == item.version)
                  .map((version) => version.label)}
          </TagInfo>
          <LastReading>
            <strong>Última leitura:</strong>{" "}
            {!item.readed_at
              ? "Sem leituras"
              : moment(item.readed_at).format("DD/MM/YY HH:mm:ss")}
          </LastReading>
        </CardMonitoring>
      </Link>
    </Col>
  );
}
