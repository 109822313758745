import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../../components/sidebar/sidebar";
import "./dashboardClient.css";
import Topbar from "../../../components/topbar/topbar";
import { Row, Col, Card, Alert, Modal, Table, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MapBrazil } from "react-brazil-map";
import { useFetch } from "../../../components/hooks/useFetch";
import { Prefix } from "../../../services/prefix";
import { uf } from "../../../components/data/uf";
import Loading from "../../../components/loader/loading";
import { Context } from "../../../services/context";
import Select from "react-select";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import Maps from "../../maps/map";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [listEquip, setListEquip] = useState({
    equips: [],
    to: 0,
  });
  const [listDevice, setListDevice] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState({
    uf: "",
    id_city: "",
    id_unit: "",
    name_unit: "",
    id_sector: "",
    name_sector: "",
    id_room: "",
    name_room: "",
    id_contract: "",
  });
  const handleClose = () => {
    setShow(false);
    setListDevice([]);
  };

  const handleDistrict = (e) => {
    setShow(true);
    setSearch({
      ...search,
      uf: e,
      id_city: "",
      id_unit: "",
      name_unit: "",
      id_sector: "",
      name_sector: "",
      id_room: "",
      name_room: "",
      id_contract: "",
    });
  };
  const handleCity = (e) => {
    setPage(1);
    setSearch({ ...search, id_city: e.value });
  };
  const handleUnit = (e) => {
    setSearch({ ...search, id_unit: e.value, name_unit: e.label });
  };
  const handleSectors = (e) => {
    setSearch({ ...search, id_sector: e.value, name_sector: e.label });
  };
  const handleRooms = (e) => {
    setShow(false);
    setSearch({ ...search, id_room: e.value, name_room: e.label });
  };

  const resetRoom = () => {
    setSearch({ ...search, id_room: "", name_room: "" });
  };
  const resetSector = () => {
    setSearch({
      ...search,
      id_sector: "",
      name_sector: "",
      id_room: "",
      name_room: "",
    });
  };
  const resetUnit = () => {
    setSearch({
      ...search,
      uf: "",
      id_city: "",
      name_city: "",
      id_unit: "",
      name_unit: "",
      id_sector: "",
      name_sector: "",
      id_room: "",
      name_room: "",
    });
  };

  const handleDevice = (e) => {
    setListDevice(listEquip.equips.filter((filtro) => filtro.id == e));
    setShow(true);
  };

  const loadMore = () => {
    setPage(parseInt(page) + 1);
  };

  const listClient = useFetch(Prefix.replace(/[\\"]/g, "") + "/client");
  const listCities = useFetch(
    Prefix.replace(/[\\"]/g, "") +
      "/cities?all=true&uf=" +
      search.uf +
      "&cities_extend_contracts=1"
  );
  const listUnits = useFetch(Prefix.replace(/[\\"]/g, "") + "/units?all=true");
  const listSectors = useFetch(
    Prefix.replace(/[\\"]/g, "") + "/sectors?all=true"
  );
  const listRooms = useFetch(Prefix.replace(/[\\"]/g, "") + "/rooms?all=true");

  useEffect(() => {
    if (page > 1) {
      api
        .get(
          Prefix.replace(/[\\"]/g, "") +
            "/contract-items?all=false&uf=" +
            search.uf +
            "&id_city=" +
            search.id_city +
            "&id_unit=" +
            search.id_unit +
            "&id_sector=" +
            search.id_sector +
            "&id_room=" +
            search.id_room +
            "&id_contract=" +
            search.id_contract +
            "&per_page=" +
            perPage +
            "&page=" +
            page
        )
        .then((response) => {
          setListEquip((prevState) => ({
            equips: [...prevState.equips, ...response.data?.models.data],
            to: response.data?.models.to,
          }));
        });
    } else {
      api
        .get(
          Prefix.replace(/[\\"]/g, "") +
            "/contract-items?all=false&uf=" +
            search.uf +
            "&id_city=" +
            search.id_city +
            "&id_unit=" +
            search.id_unit +
            "&id_sector=" +
            search.id_sector +
            "&id_room=" +
            search.id_room +
            "&id_contract=" +
            search.id_contract +
            "&per_page=" +
            perPage +
            "&page=" +
            page
        )
        .then((response) => {
          setListEquip({
            equips: response.data.models.data,
            to: response.data?.models.to,
          });
        });
    }
  }, [search, page]);

  const cities = listCities.data?.models?.map?.((map) => {
    return { value: map.id, label: map.name };
  });

  const units = listUnits.data?.models
    ?.filter?.((filtro) => filtro.id_city == search.id_city)
    .map((map) => {
      return { value: map.id, label: map.name };
    });

  const sectors = listSectors.data?.models
    .filter?.((filtro) => filtro.id_unit == search.id_unit)
    .map((map) => {
      return { value: map.id, label: map.name };
    });

  const rooms = listRooms.data?.models
    .filter?.((filtro) => filtro.id_sector == search.id_sector)
    .map((map) => {
      return { value: map.id, label: map.name };
    });

  const selectStyles = {
    height: 100,
  };

  return (
    <>
      <ToastContainer />

      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <div id="content">
          <Topbar />
          <div className="content_all">
            <Row>
              <Col sm={12} className="card-mt-4">
                <Maps />
                {/* <Card>
                  <Card.Header>
                    <h3 className="card-label">
                      Visão Geral{' '}
                      <small>Gerenciamento de equipamentos do cliente</small>
                    </h3>
                  </Card.Header>
                  <Card.Body className="height-limit">
                    
                    <Card.Text as="div">
                      <Row>
                        <Col sm="5" className="d-flex justify-content-center">
                          <MapBrazil
                            onChange={handleDistrict}
                            bg="#8f8f8f"
                            fill="#752929"
                            colorStroke="white"
                            width={500}
                            height={500}
                          />
                        </Col>
                        <Col sm="7">
                          <Row>
                            <Col sm="12" className="title-equipments">
                              <img src="../../media/icons/Location.svg" />{' '}
                              <strong>
                                <Link to="#" onClick={resetUnit}>
                                  {listClient.data?.model?.name}
                                </Link>
                              </strong>{' '}
                              <span>
                                {search.name_unit == '' ? (
                                  ''
                                ) : (
                                  <>
                                    <img src="../../media/icons/arrow.svg" />
                                    <Link to="#" onClick={resetUnit}>
                                      {search.name_unit}
                                    </Link>
                                  </>
                                )}
                                {search.name_sector == '' ? (
                                  ''
                                ) : (
                                  <>
                                    <img src="../../media/icons/arrow.svg" />
                                    <Link to="#" onClick={resetSector}>
                                      {search.name_sector}
                                    </Link>
                                  </>
                                )}
                                {search.name_room == '' ? (
                                  ''
                                ) : (
                                  <>
                                    <img src="../../media/icons/arrow.svg" />
                                    <Link to="#" onClick={resetRoom}>
                                      {search.name_room}
                                    </Link>
                                  </>
                                )}
                              </span>
                            </Col>
                            <Col sm="12">
                              <section className="box-equipments">
                                <div className="box-equipments-inner">
                                  <ul>
                                    {!listEquip.equips ||
                                    listEquip.equips.length == 0 ? (
                                      <li className="d-flex justify-content-center">
                                        Nenhum coletor encontrado, tente
                                        novamente...
                                      </li>
                                    ) : (
                                      listEquip.equips.map((map) => {
                                        return (
                                          <li
                                            onClick={() => handleDevice(map.id)}
                                          >
                                            <div className="box-equipments-info">
                                              <img src="../../media/icons/no-image.jpg" />
                                              <span>{map.nickname}</span>
                                            </div>
                                            <span
                                              style={{ marginRight: '20px' }}
                                            >
                                              {map.room.sector.unit.address +
                                                ', ' +
                                                map.room.sector.unit.number +
                                                ', ' +
                                                map.room.sector.unit.city.name +
                                                ', ' +
                                                map.room.sector.unit.city.uf}
                                            </span>
                                          </li>
                                        );
                                      })
                                    )}
                                    <li className="load-more d-flex justify-content-center">
                                      {!listEquip.equips ||
                                      listEquip.equips.length == 0 ||
                                      !listEquip.to ? (
                                        ''
                                      ) : (
                                        <Button
                                          variant="success"
                                          onClick={loadMore}
                                        >
                                          Carregar mais...
                                        </Button>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </section>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card> */}
              </Col>
            </Row>

            <Modal
              show={show}
              size={listDevice.length == 0 ? "sm" : "lg"}
              onHide={handleClose}
              keyboard={true}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {listDevice.length !== 0 ? (
                    <strong>Informações do Equipamento</strong>
                  ) : (
                    <>
                      <strong>Estado:</strong>
                      {uf
                        .filter((filtro) => filtro.value == search.uf)
                        .map((map) => map.name)}
                    </>
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {listDevice.length !== 0 ? (
                  <>
                    <Row>
                      <Col className="d-flex flex-column align-items-center mb-4">
                        <div className="box-equipment-data d-flex justify-content-center">
                          <img
                            src="../../media/icons/no-image.jpg"
                            className="mb-2"
                          />
                        </div>
                        <h2>
                          Equipamento{" "}
                          {listDevice[0].nickname +
                            " (" +
                            listDevice[0].serial +
                            ")"}
                        </h2>
                      </Col>
                    </Row>
                    <Table bordered hover responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Serial: </strong>
                            {listDevice[0].serial}
                          </td>
                          <td>
                            <strong>Nickname: </strong>
                            {listDevice[0].nickname}
                          </td>
                          <td>
                            <strong>Localização: </strong>
                            {listDevice[0].room.sector.unit.name +
                              ", " +
                              listDevice[0].room.sector.name +
                              ", " +
                              listDevice[0].room.name}
                          </td>
                          <td>
                            <strong>Endereço:</strong>{" "}
                            {listDevice[0].room.sector.unit.address +
                              ", " +
                              listDevice[0].room.sector.unit.number +
                              ", " +
                              listDevice[0].room.sector.unit.city.name +
                              ", " +
                              listDevice[0].room.sector.unit.city.uf}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Instalação:</strong>{" "}
                            {listDevice[0].installation_date}
                          </td>
                          <td>
                            <strong>Aditivo:</strong>{" "}
                            {!listDevice[0].additive ? "Não" : "Sim"}
                          </td>
                          <td>
                            <strong>Alienado: </strong>
                            {!listDevice[0].alienated ? "Não" : "Sim"}
                          </td>
                          <td>
                            <strong>Criticidade: </strong>
                            {!listDevice[0].criticity == 1
                              ? "Apoio"
                              : !listDevice[0].criticity == 2
                              ? "Rotina"
                              : "Crítico"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Tipo:</strong>{" "}
                            {listDevice[0].monitor_environment == false
                              ? "Equipamento"
                              : listDevice[0].monitor_environment == true
                              ? "Ambiente"
                              : "Não monitorável"}
                          </td>
                          <td>
                            <strong>Preventiva: </strong>
                            {listDevice[0].preventive_frequency + " meses"}
                          </td>
                          <td>
                            <strong>Calibração: </strong>
                            {listDevice[0].calibration_frequency + " meses"}
                          </td>
                          <td>
                            <strong>Marca: </strong>
                            {
                              listDevice[0].equipament_model.equipament_brand
                                .name
                            }
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Modelo:</strong>{" "}
                            {listDevice[0].equipament_model.name}
                          </td>
                          <td>
                            <strong>Família: </strong>{" "}
                            {
                              listDevice[0].equipament_model
                                ?.equipament_category.equipament_family.name
                            }
                          </td>
                          <td>
                            <strong>Identificação: </strong>
                            {listDevice[0].identification}
                          </td>
                          <td>
                            <strong>Reg. Anvisa: </strong>
                            {listDevice[0].equipament_model.anvisa_registry}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <h2 className="mt-4 mb-4 text-center">Contrato</h2>
                    <Table bordered hover responsive>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Nome: </strong>
                            {listDevice[0].contract.name}
                          </td>
                          <td>
                            <strong>Número: </strong>
                            {listDevice[0].contract.number}
                          </td>
                          <td>
                            <strong>Tipo de contrato: </strong>
                            {listDevice[0].contract.contract_type.name}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Corretiva:</strong>{" "}
                            {!listDevice[0].contract.cover_corretive
                              ? "Não"
                              : "Sim"}
                          </td>
                          <td>
                            <strong>Preventiva:</strong>{" "}
                            {!listDevice[0].contract.cover_preventive
                              ? "Não"
                              : "Sim"}
                          </td>
                          <td>
                            <strong>Calibração:</strong>{" "}
                            {!listDevice[0].contract.cover_calibration
                              ? "Não"
                              : "Sim"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Data inicial:</strong>{" "}
                            {listDevice[0].contract.start_date}
                          </td>
                          <td colspan="2">
                            <strong>Data final:</strong>{" "}
                            {listDevice[0].contract.end_date}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <Col
                    sm="12"
                    className="d-flex flex-column justify-content-center"
                  >
                    <Row>
                      <Col className="mb-4">
                        <Select
                          onChange={handleCity}
                          options={cities}
                          styles={selectStyles}
                          placeholder="Selecione uma cidade"
                        />
                      </Col>
                    </Row>
                    {search.id_city == "" ? (
                      ""
                    ) : (
                      <Row>
                        <Col className="mb-4">
                          <Select
                            onChange={handleUnit}
                            options={units}
                            styles={selectStyles}
                            placeholder="Selecione uma unidade"
                          />
                        </Col>
                      </Row>
                    )}
                    {search.id_unit == "" ? (
                      ""
                    ) : (
                      <Row>
                        <Col className="mb-4">
                          <Select
                            onChange={handleSectors}
                            options={sectors}
                            styles={selectStyles}
                            placeholder="Selecione um setor"
                          />
                        </Col>
                      </Row>
                    )}
                    {search.id_sector == "" ? (
                      ""
                    ) : (
                      <Row>
                        <Col className="mb-4">
                          <Select
                            onChange={handleRooms}
                            options={rooms}
                            styles={selectStyles}
                            placeholder="Selecione uma sala"
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
